<template>
  <ui-component-modal
    :modalTitle="$t('Components.Notes.ModalCreateNote.Modal_Title')"
    :isSaving="isSaving"
    :onClickCancel="onClickCancel"
    :onClickSave="createNote"
    :showModal="showModal"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingErrorMessage="'Something went wrong!'"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <ui-base-input-field
        class="has-margin-bottom-x2"
        :label="'Subject'"
        type="text"
        v-model="newNote.Subject"
        :placeholder="'Subject'"
        :required="true"
        :error="subjectError"
        @input="$v.newNote.Subject.$touch()"
        @blur="$v.newNote.Subject.$touch()"
      />
      <textarea
        rows="5"
        type="text"
        ref="body"
        :placeholder="'Text'"
        v-model="newNote.Body"
        class="textarea has-margin-bottom"
        @keyup.ctrl.enter="createNote"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import noteProvider from '@/providers/note'
import { required } from 'vuelidate/lib/validators'
// const touchMap = new WeakMap()

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    itemId: {
      default: 0,
      type: Number,
    },

    typeId: {
      default: 'None',
      type: String,
    },

    linkItemId: {
      default: 0,
      type: Number,
    },

    linkTypeId: {
      default: 'None',
      type: String,
    },

    viewType: {
      default: 'normal',
      type: String,
    },

    workEnvironment: {
      type: String,
      default: 'Frontend',
    },

    environmentId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      newNote: this.setNewNote(),
    }
  },

  created() {
    this.newNote = this.setNewNote()
  },

  mounted() {},

  computed: {
    subjectError() {
      const errors = []
      if (!this.$v.newNote.Subject.$dirty) return errors
      if (!this.$v.newNote.Subject.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t(
              'Components.Notes.ModalEditNote.Modal_Subtitle'
            ),
          })
        )
      }
      return errors
    },
  },

  /**
   * Form validation rules
   */
  validations() {
    return {
      newNote: {
        Subject: {
          required,
        },
      },
    }
  },

  methods: {
    createNote() {
      let self = this
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          this.isSaving = false
        } else {
          // do your submit logic here
          // 'PENDING'
          noteProvider.methods
            .createNote(self.newNote)
            .then((response) => {
              if (response.status === 201) {
                self.$emit('noteCreated', response.data)

                self.newNote = self.setNewNote()

                self.isSavingSuccess = true

                setTimeout(() => {
                  self.onClickCancel()
                }, 1500)
              }
            })
            .catch((error) => {
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },

    setNewNote() {
      return {
        Body: '',
        DeliveryAt: 0,
        DoBefore: 0,
        Id: 0,
        IsToDo: false,
        ItemId: this.itemId,
        LinkItemId: this.linkItemId,
        LinkTypeId: this.linkTypeId,
        AccountIds: [],
        Subject: '',
        TypeId: this.typeId,
        WorkEnvironment: this.workEnvironment,
        EnvironmentId: this.environmentId,
      }
    },
  },
}
</script>
